import { Injectable, inject } from '@angular/core';
import { Observable, map, shareReplay, switchMap, tap, combineLatest } from 'rxjs';

import { FinalEstimate, FinalEstimateEditData } from '@dartsales/common/core/models/estimate/final-estimate/final-estimate';
import { Project } from '@dartsales/common/core/models/project/project';

import { FinalEstimateStatus } from '../models/estimate/final-estimate/final-estimate-status';
import { FetchListOptions } from '../models/list-utilities/fetch-list-options';
import { SortDirection } from '../enums/sort-direction';
import { Estimate, EstimateId } from '../models/estimate/estimate';
import { PagedList } from '../models/list-utilities/paged-list';
import { ListManager, InfiniteScrollListStrategy } from '../utils/list-manager';

import { FinalEstimateApiService } from './api/final-estimate-api.service';
import { ProjectLayoutService } from './project-layout.service';

/** Service with final estimates for the current project. */
@Injectable()
export class ProjectFinalEstimatesService {

  private readonly finalEstimateApiService = inject(FinalEstimateApiService);

  private readonly projectLayoutService = inject(ProjectLayoutService);

  private readonly refresh$ = this.projectLayoutService.refresh$;

  private readonly projectId$ = inject(ProjectLayoutService).projectId$;

  /** Final estimates. */
  public readonly estimates$ = this.createFinalEstimatesStream(this.projectId$);

  /** Final awarded estimate. */
  public readonly awardedEstimate$ = this.createFinalAwardedEstimateStream(this.estimates$);

  /** Number of included alternates. */
  public readonly includedAlternatesCount$ = this.createIncludedAlternatesCountStream(this.awardedEstimate$);

  /** Create alternate estimates list manager. */
  public createEstimateListManager(): ListManager<FinalEstimate, Project['id']> {
    const filter$ = this.refresh$.pipe(
      switchMap(() => this.projectId$),
    );

    return new ListManager<FinalEstimate, Project['id']>({
      strategy: new InfiniteScrollListStrategy(),
      filter$,
      initialSort: [{ field: 'order', direction: SortDirection.ASC }],
      request: options => this.getFinalEstimatesList(options.filter, options),
    });
  }

  /**
   * Create a new final estimate.
   * @param id Base estimate ID.
   * @param data Data required to create a new final estimate.
   */
  public create(id: EstimateId, data: FinalEstimateEditData): Observable<FinalEstimate['id']> {
    return this.save(() => this.finalEstimateApiService.create(id, data));
  }

  /**
   * Update final estimate.
   * @param id Final estimate ID.
   * @param data Data required to update final estimate.
   */
  public update(id: FinalEstimate['id'], data: FinalEstimateEditData): Observable<void> {
    return this.save(() => this.finalEstimateApiService.update(id, data));
  }

  /**
   * Update final estimates order.
   * @param estimates List of final estimates to update.
   */
  public updateOrder(estimates: readonly FinalEstimate[]): Observable<void> {
    return this.save(() => this.finalEstimateApiService.updateOrder(estimates));
  }

  /**
   * Delete final estimate.
   * @param id ID.
   */
  public delete(id: FinalEstimate['id']): Observable<void> {
    return this.save(() => this.finalEstimateApiService.deleteById(id));
  }

  private save<T>(saveRequest: () => Observable<T>): Observable<T> {
    return saveRequest().pipe(
      tap(() => this.refresh()),
    );
  }

  private refresh(): void {
    this.projectLayoutService.refreshProject();
  }

  private createFinalEstimatesStream(projectId$: Observable<Project['id']>): Observable<FinalEstimate[]> {
    // We don't use 'shareReplay' here intentionally. We want to request finalEstimate from server every time.
    return combineLatest([
      projectId$,
      this.refresh$,
    ]).pipe(
      switchMap(([id]) => {
        const listOptions: FetchListOptions<Project['id']> = {
          filter: id,
          sort: [{ field: 'order', direction: SortDirection.ASC }],
        };

        return this.getFinalEstimatesList(id, listOptions);
      }),
      map(page => [...page.items]),
    );
  }

  private getFinalEstimatesList(
    projectId: Project['id'],
    listOptions?: FetchListOptions<Estimate['id']>,
  ): Observable<PagedList<FinalEstimate>> {
    return this.finalEstimateApiService.getList(projectId, listOptions);
  }

  private createFinalAwardedEstimateStream(finalEstimates$: Observable<FinalEstimate[]>): Observable<FinalEstimate | null> {
    return finalEstimates$.pipe(
      map(estimates => estimates.find(estimate => estimate.status === FinalEstimateStatus.Awarded) ?? null),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }

  private createIncludedAlternatesCountStream(awardedEstimate$: Observable<FinalEstimate | null>): Observable<number> {
    return awardedEstimate$.pipe(
      map(estimate => estimate?.includedAlternateEstimates.length ?? 0),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }
}
