import { Injectable, inject } from '@angular/core';
import { Observable, map, shareReplay, switchMap, tap, combineLatest } from 'rxjs';

import { CreateChangeOrder, PreviewChangeOrder, EditChangeOrder } from '../models/estimate/change-order/change-order';
import { SortDirection } from '../enums/sort-direction';
import { FetchListOptions } from '../models/list-utilities/fetch-list-options';
import { Project } from '../models/project/project';
import { PagedList } from '../models/list-utilities/paged-list';
import { EstimateId } from '../models/estimate/estimate';
import { InfiniteScrollListStrategy, ListManager } from '../utils/list-manager';

import { ProjectLayoutService } from './project-layout.service';
import { ChangeOrdersApiService } from './api/change-orders-api.service';

/** Service with change orders for the current project. */
@Injectable()
export class ProjectChangeOrdersService {

  private readonly changeOrdersApiService = inject(ChangeOrdersApiService);

  private readonly projectLayoutService = inject(ProjectLayoutService);

  private readonly refresh$ = this.projectLayoutService.refresh$;

  private readonly projectId$ = this.projectLayoutService.projectId$;

  /** List of change orders for the project. */
  public readonly changeOrders$ = this.createChangeOrdersStream();

  /** Whether the project has change orders. */
  public readonly hasChangeOrders$ = this.createHasChangeOrdersStream();

  /** Create change orders list manager. */
  public createListManager(): ListManager<PreviewChangeOrder, Project['id']> {
    const filter$ = this.refresh$.pipe(
      switchMap(() => this.projectId$),
    );
    return new ListManager<PreviewChangeOrder, Project['id']>({
      strategy: new InfiniteScrollListStrategy(),
      filter$,
      initialSort: [{ field: 'order', direction: SortDirection.ASC }],
      request: options => this.getChangeOrdersList(options.filter, options),
    });
  }

  /**
   * Add a new change order to the project.
   * @param id Change order ID.
   * @param data Data required to create a new change order.
   */
  public addToProject(id: EstimateId, data: CreateChangeOrder): Observable<EstimateId> {
    return this.save(() => this.changeOrdersApiService.create(id, data));
  }

  /**
   * Update change order.
   * @param id Change order ID.
   * @param data Updated change order data.
   */
  public update(id: EstimateId, data: EditChangeOrder): Observable<void> {
    return this.save(() => this.changeOrdersApiService.update(id, data));
  }

  /**
   * Delete a change order.
   * @param id Change order ID.
   */
  public remove(id: EstimateId): Observable<void> {
    return this.save(() => this.changeOrdersApiService.deleteById(id));
  }

  /**
   * Update change orders list order.
   * @param estimates List of change orders to update.
   */
  public updateOrder(estimates: readonly PreviewChangeOrder[]): Observable<void> {
    return this.save(() => this.changeOrdersApiService.updateOrder(estimates));
  }

  private save<T>(saveRequest: () => Observable<T>): Observable<T> {
    return saveRequest().pipe(
      tap(() => this.refresh()),
    );
  }

  private refresh(): void {
    this.projectLayoutService.refreshProject();
  }

  private getChangeOrdersList(
    id: Project['id'], options?: FetchListOptions<Project['id']>,
  ): Observable<PagedList<PreviewChangeOrder>> {
    return this.changeOrdersApiService.getList(id, options);
  }

  private createChangeOrdersStream(): Observable<PreviewChangeOrder[]> {
    return combineLatest([
      this.projectId$,
      this.refresh$,
    ]).pipe(
      switchMap(([id]) => {
        const listOptions: FetchListOptions<Project['id']> = {
          filter: id,
          sort: [{ field: 'order', direction: SortDirection.ASC }],
        };

        return this.getChangeOrdersList(id, listOptions);
      }),
      map(page => [...page.items]),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }

  private createHasChangeOrdersStream(): Observable<boolean> {
    return this.changeOrders$.pipe(
      map(changeOrders => changeOrders.length > 0),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }
}
