import { Injectable, inject } from '@angular/core';
import { Observable, combineLatest, first, map, shareReplay, switchMap, tap } from 'rxjs';

import { CreateAlternateEstimateData, OverviewAlternateEstimate } from '@dartsales/common/core/models/estimate/alternate-estimate';
import { TermMap } from '@dartsales/common/core/models/term-map';

import { EstimateService } from '../models/estimate-services/estimate-service';
import { EditEstimateDetails, Estimate, EstimateId } from '../models/estimate/estimate';
import { TermSummary } from '../models/estimate-services/term-summary';
import { FetchListOptions } from '../models/list-utilities/fetch-list-options';
import { Project } from '../models/project/project';
import { SortDirection } from '../enums/sort-direction';
import { PaginationData } from '../models/list-utilities/pagination-data';
import { PagedList } from '../models/list-utilities/paged-list';
import { InfiniteScrollListStrategy, ListManager } from '../utils/list-manager';

import { AlternateEstimateApiService } from './api/alternate-estimate-api.service';
import { BaseEstimateApiService } from './api/base-estimate-api.service';
import { EstimateServicesApiService } from './api/estimate-services-api.service';
import { ProjectLayoutService } from './project-layout.service';
import { CommonEstimateApiService } from './api/common-estimate-api.service';

/** Service with alternate estimates for the current project. */
@Injectable()
export class ProjectAlternateEstimatesService {

  private readonly alternateEstimateApiService = inject(AlternateEstimateApiService);

  private readonly baseEstimateApiService = inject(BaseEstimateApiService);

  private readonly estimateServicesApiService = inject(EstimateServicesApiService);

  private readonly commonEstimateApiService = inject(CommonEstimateApiService);

  private readonly projectLayoutService = inject(ProjectLayoutService);

  private readonly refresh$ = this.projectLayoutService.refresh$;

  private readonly projectId$ = this.projectLayoutService.projectId$;

  /** Alternate estimates. */
  public readonly estimates$ = this.createAlternateEstimatesStream(this.projectId$);

  /** Number of project's alternate estimates from base estimate including removed ones. */
  public readonly alternateEstimatesCounter$ = this.createAlternateEstimatesCounterStream(this.projectId$);

  /** Number of project's alternate estimates from list. */
  public readonly listedAlternateEstimatesCount$ = this.getAlternateEstimatesCountStream(this.projectId$);

  /** Create alternate estimates list manager. */
  public createEstimateListManager(): ListManager<OverviewAlternateEstimate, Project['id']> {
    const filter$ = this.refresh$.pipe(
      switchMap(() => this.projectId$),
    );
    return new ListManager<OverviewAlternateEstimate, Project['id']>({
      strategy: new InfiniteScrollListStrategy(),
      filter$,
      initialSort: [{ field: 'order', direction: SortDirection.ASC }],
      request: options => this.getAlternateEstimatesList(options.filter, options),
    });
  }

  /** Get new alternate name. */
  public getNewAlternateName(): Observable<string> {
    return this.projectId$.pipe(
      switchMap(id => this.alternateEstimateApiService.getNewAlternateName(id)),
    );
  }

  /**
   * Add a new alternate estimate to project.
   * @param data Data required to create a new alternate estimate.
   */
  public addToProject(data: CreateAlternateEstimateData): Observable<Estimate['id']> {
    return this.save(projectId => this.alternateEstimateApiService.create(projectId, data));
  }

  /**
   * Update estimate details.
   * @param estimateId Estimate id.
   * @param updatedEstimateDetails Updated estimate details.
   */
  public updateAlternateEstimateDetails(estimateId: EstimateId, updatedEstimateDetails: EditEstimateDetails): Observable<void> {
    return this.save(() => this.commonEstimateApiService.updateEstimateDetails(estimateId, updatedEstimateDetails));
  }

  /**
   * Update alternate estimates order.
   * @param estimates List of alternate estimates to update.
   */
  public updateAlternateEstimateOrder(estimates: readonly OverviewAlternateEstimate[]): Observable<void> {
    return this.save(() => this.alternateEstimateApiService.updateOrder(estimates));
  }

  /**
   * Delete an alternate estimate.
   * @param id Alternate estimate ID.
   */
  public remove(id: Estimate['id']): Observable<void> {
    return this.save(() => this.alternateEstimateApiService.deleteById(id));
  }

  /**
   * Convert term to a new alternate estimate.
   * @param termId Term id.
   */
  public convertFromTerm(termId: EstimateService['termId']): Observable<OverviewAlternateEstimate['id']> {
    return this.save(() => this.estimateServicesApiService.createAlternateEstimateFromTerm(termId));
  }

  /**
   * Convert all terms to alternate estimates.
   * @param estimateId Estimate ID.
   * @param termIds Term ids.
   */
  public convertFromAllTerms(
    estimateId: EstimateId,
    termIds: readonly TermSummary['id'][],
  ): Observable<TermMap[]> {
    return this.save(() => this.estimateServicesApiService.createAlternateEstimatesFromAllTerms(estimateId, termIds));
  }

  private save<T>(saveRequest: (projectId: number) => Observable<T>): Observable<T> {
    return this.projectId$.pipe(
      first(),
      switchMap(projectId => saveRequest(projectId)),
      tap(() => this.refresh()),
    );
  }

  private refresh(): void {
    this.projectLayoutService.refreshProject();
  }

  private getAlternateEstimatesList(
    id: Project['id'], options?: FetchListOptions<Project['id']>,
  ): Observable<PagedList<OverviewAlternateEstimate>> {
    return this.alternateEstimateApiService.getList(id, options);
  }

  private createAlternateEstimatesStream(projectId$: Observable<Estimate['id']>): Observable<OverviewAlternateEstimate[]> {
    return combineLatest([
      projectId$,
      this.refresh$,
    ]).pipe(
      switchMap(([id]) => {
        const listOptions: FetchListOptions<Project['id']> = { filter: id, sort: [{ field: 'order', direction: SortDirection.ASC }] };
        return this.getAlternateEstimatesList(id, listOptions);
      }),
      map(page => [...page.items]),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }

  private createAlternateEstimatesCounterStream(projectId$: Observable<Estimate['id']>): Observable<number> {
    return combineLatest([
      projectId$,
      this.refresh$,
    ]).pipe(
      switchMap(([id]) => this.baseEstimateApiService.getAlternatesCounter(id)),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }

  private getAlternateEstimatesCountStream(projectId$: Observable<Estimate['id']>): Observable<number> {
    return combineLatest([
      projectId$,
      this.refresh$,
    ]).pipe(
      switchMap(([id]) => this.getAlternateEstimatesList(id, { pagination: new PaginationData({ pageSize: 1 }), filter: id })),
      map(page => page.pagination.totalCount),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }
}
